export function firstAncestorOrDefault(
  element: Element,
  predicate: (element: Element) => boolean,
  includeSelf = false
): Element | null {
  let parent: Element | null = includeSelf ? element : element.parentElement;

  while(parent) {
    if(predicate(parent)) {
      return parent;
    }

    parent = parent.parentElement;
  }

  return null;
}
