<script lang="ts">
  export let title: string;
  export let text: string;
  export let link: string;
  export let tagType: string;
  export let tagText: string;
</script>

<a href="{ `${link}` }" class="search-listing-item">
  { #if tagType && tagText }
    <span class="tag { tagType.toLowerCase() }">{ tagText }</span>
  { /if }

  <h2>{ title }</h2>

  { #if text && text !== ''}
    <p>{ text }</p>
  { /if }

  <i class="xlent-icon-arrow-right"></i>
</a>
