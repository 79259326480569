export function updateQueryParameter(queryString: string, parameter: string, value: string): string {
  const queryParts = queryString.split('&');

  const updatedQueryParts = queryParts.map((queryPart) => {
    if(queryPart.startsWith(parameter)) {
      return `${parameter}=${value}`;
    }
    return queryPart;
  });

  return updatedQueryParts.join('&');
}

export const apiUrl = `${window.location.origin}/api`;
