export function toggleOverlay(element: HTMLElement | Element | null | undefined): void {
  if(element === null || element === undefined) {
    return;
  }

  element.classList.toggle('show-overlay');
  toggleNoScroll();
}

function toggleNoScroll(): void {
  const anyOverlayOpen = document.querySelectorAll('.show-overlay').length > 0;

  if(anyOverlayOpen) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
}
