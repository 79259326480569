<svelte:options
  customElement={{
    tag: 'xlent-listing-item',
    shadow: 'none',
    props: {
      linkText: { attribute: 'link-text' },
      tagType: { attribute: 'tag-type' },
      tagText: { attribute: 'tag-text' },
      imageSrc: { attribute: 'image-src' },
      bgBrandColor: { attribute: 'bg-brand-color' }
    }
  }}
/>

<script lang="ts">
  import { onMount } from 'svelte';
  import { setupEnterScreenAnimations } from '../utils/animation-utils';

  export let title: string;
  export let link: string;
  export let linkText: string;
  export let tagType: string | null = null;
  export let tagText: string | null = null;
  export let imageSrc: string | null = null;
  export let bgBrandColor: string | null = null;
  export let large = false;

  onMount(() => {
    setupEnterScreenAnimations(['.listing-item']);
  });
</script>

<div class="listing-item { large ? 'large' : '' }" data-click-target="a">

  { #if imageSrc }
    <picture>
      <source srcset="{ imageSrc }?width=800&amp;format=webp" media="(min-width: 1200px)" type="image/webp">
      <source srcset="{ imageSrc }?width=800&amp;format=jpg" media="(min-width: 1200px)" type="image/jpeg">

      <source srcset="{ imageSrc }?width=1140&amp;format=webp" media="(min-width: 700px)" type="image/webp">
      <source srcset="{ imageSrc }?width=1140&amp;format=jpg" media="(min-width: 700px)" type="image/jpeg">

      <source srcset="{ imageSrc }?width=700&amp;format=webp" media="(min-width: 500px)" type="image/webp">
      <source srcset="{ imageSrc }?width=700&amp;format=jpg" media="(min-width: 500px)" type="image/jpeg">

      <source srcset="{ imageSrc }?width=500&amp;format=webp" media="(min-width: 0)" type="image/webp">
      <source srcset="{ imageSrc }?width=500&amp;format=jpg" media="(min-width: 0)" type="image/jpeg">

      <img src="{ imageSrc }" alt="" loading="lazy" />
    </picture>
  { :else }
    <div class="media" style="{ bgBrandColor ? `background: ${bgBrandColor};` : '' }"></div>
  { /if }

  { #if tagType !== null && tagText !== null }
    <span class="tag { tagType.toLowerCase() }">{ tagText }</span>
  { /if }

  <span class="title">
    { title }
  </span>

  <a href="{ link }">
    { linkText }
    <span class="visually-hidden">: { title }</span>
    <i class="xlent-icon-arrow-right"></i>
  </a>
</div>
