<svelte:options
  customElement={{
    tag: 'xlent-search-modal',
    shadow: 'none',
    props: {
      numberOfSearchResultsText: { attribute: 'number-of-search-results-text' },
      placeholderText: { attribute: 'placeholder-text' },
      noResultsText: { attribute: 'no-results-text' }
    }
  }}
/>

<script lang="ts">
  import { toggleOverlay } from '../utils/overlay';
  import SearchResults from './SearchResults.svelte';

  export let numberOfSearchResultsText: string;
  export let placeholderText: string;
  export let noResultsText: string;

  let searchModal: HTMLElement;
  let searchValue = '';

  function hideSearchModal(): void {
    toggleOverlay(searchModal);
  }
</script>

<div class="search-modal" bind:this={ searchModal }>
  <div class="content-container">
    <div class="search-wrapper">
      <i class="xlent-icon-magnifying-glass"></i>
      <input type="search" bind:value={ searchValue } placeholder="{ placeholderText }"/>
    </div>

    <SearchResults searchTerm={ searchValue } numberOfSearchResultsText="{ numberOfSearchResultsText }" noResultsText="{ noResultsText }"/>

    <button class="close-button" on:click={ hideSearchModal }>
      <i class="xlent-icon-close-search"></i>
    </button>
  </div>
</div>
