<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import Loader from './Loader.svelte';

  export let showButton: boolean;
  export let buttonText: string;
  export let loading: boolean;

  const dispatch = createEventDispatcher();

  function buttonClickEvent():void{
    dispatch('click');
  }
</script>

{ #if loading || showButton }
  <div class="button-wrapper">
    { #if loading }
      <Loader />
    { :else if showButton }
      <button on:click={ buttonClickEvent }>
        { buttonText }
      </button>
    { /if }
  </div>
{ /if }
