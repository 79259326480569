<svelte:options
  customElement={{
    tag: 'xlent-coworker-listing',
    shadow: 'none',
    props: {
      randomize: { type: 'Boolean' },
      officeLocation: { attribute: 'office-location' },
      loadNumber: { attribute: 'load-number', type: 'Number' },
      showAllText: { attribute: 'show-all-text' }
    }
  }}
/>

<script lang='ts'>
  import { onMount } from 'svelte';
  import { apiUrl } from '../utils/url';
  import LoadButton from './LoadButton.svelte';
  import type { Coworker } from '../utils/types';

  export let randomize: boolean;
  export let officeLocation: string;
  export let loadNumber = 0;
  export let showAllText: string;

  let loading = false;
  let coworkers: Coworker[] = [];
  let showLoadMore = false;

  async function loadCoworkers(grab: number, asRandom: boolean): Promise<void> {
    loading = true;

    await fetch(
      `${apiUrl}/coworkers/getlimited?random=${asRandom}&officeLocation=${officeLocation}&take=${grab}`
    )
      .then((response) => response.json())
      .then((data) => {
        coworkers = data.listing;

        if(grab !== 0 && data.totalNumberOfItems > grab) {
          showLoadMore = true;
        } else {
          showLoadMore = false;
        }

        loading = false;
      });
  }

  async function loadRemainingCoworkers(exclude: Coworker[]): Promise<void> {
    loading = true;
    var request = new Request(`${apiUrl}/coworkers/getremainingexcluded?officeLocation=${officeLocation}`,
      {
        method:'post',
        headers: {
          'content-type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(exclude)
      });

    await fetch(request)
      .then((response) => response.json())
      .then((data) => {
        coworkers = coworkers.concat(data.listing);
        showLoadMore = false;
        loading = false;
      });
  }

  async function loadAllCoworkers(): Promise<void> {
    await loadRemainingCoworkers(coworkers);
  }

  onMount(async () => {
    await loadCoworkers(loadNumber, randomize);
  });
</script>

<div class="coworker-listing">
  <div class="listing-container">
    {#each coworkers as coworker}
      <div class="listing-item">
        {#if coworker.imageUrl !== null}

          <picture>
            <source class="avatar" srcset="{ coworker.imageUrl }&amp;format=webp" type="image/webp">
            <source class="avatar" srcset="{ coworker.imageUrl }&amp;format=jpg" type="image/jpeg">

            <img
              src={ coworker.imageUrl }
              alt=""
              loading="lazy"
            />
          </picture>

        {:else}
          <img class="load-error" alt="" src="" loading="lazy" />
        {/if}
        <div class="coworker-name">{coworker.name}</div>
      </div>
    {/each}
  </div>

  <LoadButton on:click={loadAllCoworkers}
    showButton="{ showLoadMore }"
    buttonText="{ showAllText }"
    loading="{ loading }">
  </LoadButton>

</div>
