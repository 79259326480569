export default function initCopyToClipboard(): void {
  addCopyCodeButtons();
}

function addCopyCodeButtons(): void {
  const copyButtons = document.querySelectorAll('.code-block .copy');
  copyButtons.forEach((button) => {
    button.addEventListener('click', copyCode);
  });
}

function copyCode(event: Event): void {
  const button = event.currentTarget as HTMLElement;
  const toolTip = button.querySelector<HTMLElement>('.tooltip');
  const codeElement = button.closest('.code-block')?.querySelector('code');

  if(codeElement) {
    const codeContent = codeElement.textContent || codeElement.innerText;

    navigator.clipboard.writeText(codeContent)
      .then(() => {
        toolTip?.classList.remove('hidden');

        button.addEventListener('mouseleave', () => {
          toolTip?.classList.add('hidden');
        });
      })
      .catch((err) => {
        console.error('Unable to copy code: ', err);
      });
  }
}
