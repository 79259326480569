import './styles/main.scss';

import initMainMenu from './scripts/main-menu';
import initWrapper from './scripts/wrapper';
import initAnimations from './scripts/setup-animations';
import initCopyToClipboard from './scripts/copy-to-clipboard';
import initClickableContainers from './scripts/clickable-containers';

export * from './components/custom-elements';

//Load scripts
initMainMenu();
initWrapper();
initAnimations();
initCopyToClipboard();
initClickableContainers();
