<svelte:options
  customElement={{
    tag: 'xlent-listing',
    shadow: 'none',
    props: {
      showTags: { attribute: 'show-tags' },
      pageSize: { attribute: 'page-size', type: 'Number' },
      queryString: { attribute: 'query-string' },
      loadMoreText: { attribute: 'load-more-text' },
      noResultsText: { attribute: 'no-results-text' }
    }
  }}
/>

<script lang="ts">
  import { onMount } from 'svelte';
  import { MediaType, type APIListingItem } from '../utils/types';
  import ListingItem from './ListingItem.svelte';
  import LoadButton from './LoadButton.svelte';
  import { apiUrl } from '../utils/url';

  export let showTags: boolean;
  export let pageSize: number;
  export let queryString: string;
  export let loadMoreText: string;
  export let noResultsText: string;

  let lastQueryString = queryString;

  let listing: APIListingItem[] = [];
  let totalNumberOfItems: number;
  let loading = false;
  const culture = document.querySelector('html')?.getAttribute('lang');

  async function fetchNextPage(): Promise<void> {
    const itemsSkip = listing.length;
    const itemsTake = pageSize;

    loading = true;
    await fetch(`${apiUrl}/listings?culture=${culture}&${queryString}&skip=${itemsSkip}&take=${itemsTake}`)
      .then((response) => response.json())
      .then((data) => {
        listing = listing.concat(data.listing);
        totalNumberOfItems = data.totalNumberOfItems;
        loading = false;
      });
  }

  onMount(async () => {
    await fetchNextPage();
  });

  $: reachedEndOfList = listing.length >= totalNumberOfItems;
  $: if(queryString !== lastQueryString) {
    listing = [];
    lastQueryString = queryString;
    fetchNextPage();
  }
</script>

<div class="listing">
  <div class="listing-container">
    { #each listing as item }
      <ListingItem
        title="{ item.title }"
        link="{ item.url }"
        linkText="{ `${item.buttonTextPrefix} ${item.tagText.toLowerCase()}` }"
        tagText="{ showTags === true ? item.tagText : null }"
        tagType="{ item.tagType }"
        imageSrc="{ item.mediaType === MediaType.Image ? item.mediaSource : null }"
        bgBrandColor="{ item.mediaType === MediaType.BackgroundColor ? item.mediaSource : null }">
      </ListingItem>
    { /each }
  </div>

  <LoadButton on:click={fetchNextPage}
    showButton="{ !reachedEndOfList }"
    buttonText="{ loadMoreText }"
    loading="{ loading }">
  </LoadButton>

  { #if !loading && listing.length <= 0}
    <p class="no-results">{ noResultsText }</p>
  { /if }

</div>
