<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  let infiniteScroll: HTMLElement;
  const dispatch = createEventDispatcher<{reachedBottom: boolean}>();

  function parseScroll(): void {
    const scrollableHeight = infiniteScroll.scrollHeight - infiniteScroll.clientHeight;
    const scrollTop = infiniteScroll.scrollTop;

    if(scrollTop === 0) {
      dispatch('reachedBottom', false);
      return;
    }

    const reachedBottom = scrollTop >= scrollableHeight - 20;
    dispatch('reachedBottom', reachedBottom);
  }
</script>

<div class="infinite-scroll" bind:this={ infiniteScroll } on:scroll={ parseScroll }>
  <slot></slot>
</div>
