import { animate } from '../utils/animation-utils';
import { toggleOverlay } from '../utils/overlay';

export default function init(): void {
  initCloseMenuOnAnchorLinkClick();
  initCurrentPageHighlight();
  initMenuToggle();
  initSubmenuToggle();
  initTransparentMenu();
  initOpenSearch();
}

function initMenuToggle(): void {
  const openMenuButton = document.querySelector('.open-menu-button');
  const menuContainer = document.querySelector('.menu-container');

  openMenuButton?.addEventListener('click', () => {
    toggleOverlay(menuContainer);
  });
}

function initSubmenuToggle(): void {
  const expandableMenuItems = document.querySelectorAll('.expandable-menu-item');

  expandableMenuItems.forEach((expandableMenuItem) => {
    const expandButton = expandableMenuItem.querySelector('.expand-button');
    const submenuContainer = expandableMenuItem.querySelector<HTMLElement>('.submenu-container');

    expandButton?.addEventListener('click', () => {
      expandableMenuItems.forEach((otherExpandableMenuItem) => {
        if(otherExpandableMenuItem !== expandableMenuItem) {
          otherExpandableMenuItem.classList.remove('show-submenu');
        }
      });

      expandableMenuItem?.classList.toggle('show-submenu');
      animate(submenuContainer, 'clip-down');
    });
  });
}

function initCurrentPageHighlight(): void {
  const currentPageUrl = cleanUrl(window.location.pathname);
  const menuItemLinks = document.querySelectorAll<HTMLAnchorElement>('.menu-items li a, .menu-items .expandable-menu-item ul li a');

  menuItemLinks.forEach((menuItemLink) => {
    const menuItemUrl = cleanUrl(menuItemLink.getAttribute('href') ?? '');

    const isStartPage = currentPageUrl === '';
    const isCurrentPage = currentPageUrl === menuItemUrl;
    if(!isStartPage && isCurrentPage) {
      // Because of the way the way that different menu items need to be highlighted,
      // we need to do some extra checks to see what item to highlight.
      const submenuItem = menuItemLink.closest('.expandable-menu-item li');
      const linkContainer = menuItemLink.closest('.link-container');
      if(submenuItem !== null) {
        submenuItem.classList.add('current');
      } else if(linkContainer !== null) {
        linkContainer.classList.add('current');
      } else {
        menuItemLink.classList.add('current');
      }
    }
  });
}

function cleanUrl(url: string): string {
  const cleanUrlPattern = /\/$/;
  return url.replace(cleanUrlPattern, '');
}

function initTransparentMenu(): void {
  const menuContainer = document.querySelector('.menu-container');
  const header = document.querySelector('.header');

  if(header) {
    if(window.scrollY === 0) {
      menuContainer?.classList.add('transparent');
    }

    window.addEventListener('scroll', () => {
      const scrollPosition = window.scrollY;

      if(scrollPosition === 0) {
        menuContainer?.classList.add('transparent');
      } else {
        menuContainer?.classList.remove('transparent');
      }
    });
  }
}

function initCloseMenuOnAnchorLinkClick(): void {
  const menuContainer = document.querySelector('.menu-container');
  const menuAnchorLinks = menuContainer?.querySelectorAll('.expandable-menu-item a[href*="#"]');

  menuAnchorLinks?.forEach((anchorLink) => {
    anchorLink.addEventListener('click', () => {
      const submenuItem = anchorLink.closest('.expandable-menu-item');
      submenuItem?.classList.remove('show-submenu');

      if(menuContainer?.classList.contains('show-overlay')) {
        toggleOverlay(menuContainer);
      }
    });
  });
}

function initOpenSearch(): void {
  const openSearchButton = document.querySelector('.search-link');
  const menuContainer = document.querySelector('.menu-container');

  openSearchButton?.addEventListener('click', () => {
    menuContainer?.classList.remove('show-overlay');
    const searchModal = document.querySelector('.search-modal');
    const searchInput = searchModal?.querySelector('input');
    toggleOverlay(searchModal);
    searchInput?.focus();
  });
}
