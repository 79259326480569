export enum MediaType {
  Image = 0,
  Video = 1,
  BackgroundColor = 2,
}

export interface APIListingItem {
  title: string;
  tagType: string;
  tagText: string;
  buttonTextPrefix: string;
  url: string;
  mediaSource: string;
  addBlurOverlay: boolean;
  mediaType: MediaType;
}

export interface APISearchListingItem {
  title: string;
  text: string;
  tagType: string;
  tagText: string;
  pagePath: string;
}

export interface Filter {
  QueryParameter: string;
  TranslationValue: string;
}

export interface Option {
  value: string;
  label: string;
}

export interface Coworker {
  name: string;
  imageUrl: string;
}
