import { animate, setupEnterScreenAnimations } from '../utils/animation-utils';

export default function initAnimations(): void {
  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  if(prefersReducedMotion) {
    return;
  }

  initEnterScreenAnimations();
  initHeaderAnimations();
}

function initEnterScreenAnimations(): void {
  setupEnterScreenAnimations([
    '.content-block .media-container',
    '.highlighted-case-listing-block .highlighted-case',
    '.offer-teaser-listing-block .offer-teaser',
    '.main-offer-block .media',
    '.promotion-block .content',
    '.stat-block', '.office-listing'
  ]);
}

function initHeaderAnimations(): void {
  const header = document.querySelector('.header');
  if(header === null) {
    return;
  }

  const heading = header.querySelector('h1');
  const ingress = header.querySelector('p');
  const ctaBlock = header.querySelector<HTMLElement>('.cta-block');
  const attachmentButtonBlock = header.querySelector<HTMLElement>('.attachment-button-block');
  const logo = header.querySelector<HTMLElement>('.logo');

  animate(heading, 'clip-right', 100);
  animate(ingress, 'clip-right', 100);
  animate(logo, 'clip-right', 100);
  animate(ctaBlock, 'fade-up', 650);
  animate(attachmentButtonBlock, 'fade-up', 650);
}
