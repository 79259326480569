<script lang="ts">
  import type { APISearchListingItem } from '../utils/types';
  import SearchListingItem from './SearchListingItem.svelte';
  import { apiUrl } from '../utils/url';
  import Loader from './Loader.svelte';
  import InfiniteScroll from './InfiniteScroll.svelte';

  export let searchTerm: string;
  export let numberOfSearchResultsText: string;
  export let noResultsText: string;

  const take = 10;
  const minSearchLength = 2;
  let listing: APISearchListingItem[] = [];
  let totalNumberOfItems = -1;
  let loading = false;
  let reachedBottomOfList = false;

  let abortController: AbortController | null = null;

  async function fetchSearchResults(): Promise<void> {
    if(totalNumberOfItems !== -1 && listing.length === totalNumberOfItems) {
      return;
    }

    const culture = document.querySelector('meta[name="culture"]')?.getAttribute('content');

    abortController = new AbortController();
    loading = true;
    await fetch(`${apiUrl}/search?searchTerm=${searchTerm}&skip=${listing.length}&take=${take}&culture=${culture}`, { signal: abortController.signal })
      .then((response) => response.json())
      .then((data) => {
        if(data.searchResults === undefined || data.totalNumberOfItems === undefined) {
          return;
        }
        listing = listing.concat(data.searchResults);
        totalNumberOfItems = data.totalNumberOfItems;
      })
      .catch((error) => {
        if(error .name !== 'AbortError') {
          console.error(error);
        }
      })
      .finally(() => loading = false);
  }

  $: if(reachedBottomOfList && listing.length < totalNumberOfItems) {
    fetchSearchResults();
  }

  $: {
    listing = [];
    totalNumberOfItems = -1;
    abortController?.abort();

    if(searchTerm.replace(/[^A-ZÅÄÖa-zåäö0-9]/g, '').length >= minSearchLength) {
      fetchSearchResults();
    }
  }

  const setReachedBottom = (e: CustomEvent<boolean>): void => { reachedBottomOfList = e.detail; };
</script>

<div class="search-results { reachedBottomOfList ? 'reached-bottom' : '' }">
  { #if listing.length > 0 }
    <span class="number-of-results">{ totalNumberOfItems } { numberOfSearchResultsText }</span>
    <InfiniteScroll on:reachedBottom={ setReachedBottom }>
      { #each listing as item }
        <SearchListingItem
          title="{ item.title }"
          text="{ item.text }"
          link="{ item.pagePath }"
          tagText="{ item.tagText }"
          tagType="{ item.tagType }">
        </SearchListingItem>
      { /each }
    </InfiniteScroll>
  { :else if searchTerm.length >= minSearchLength && !loading }
    <div class="search-listing-empty">
      <span>{ noResultsText }</span>
    </div>
  { /if }

  { #if loading }
    <Loader />
  { /if }
</div>
