import { firstAncestorOrDefault } from '../utils/dom-utils';

const ME_SECONDARY_BUTTON = 2;

export default function initClickableContainers(rootElement: Element | Document = document.body): void {
  const handleClick = (event: Event): void => {
    const mouseEvent = event as MouseEvent;

    if(mouseEvent.button === ME_SECONDARY_BUTTON) {
      // Ignore right clicks
      return;
    }

    const clickedElement = event.target as HTMLElement;

    const clickTargetAncestor = firstAncestorOrDefault(clickedElement, element => element.matches('[data-click-target]'));
    if(!clickedElement.matches('[data-click-target]') && !clickTargetAncestor) {
      // No click target found
      return;
    }

    const clickIgnoreAncestor = firstAncestorOrDefault(clickedElement, element => element.matches('[data-click-ignore]'));
    if(clickedElement.matches('[data-click-ignore]') || !!clickIgnoreAncestor) {
      // Click should be ignored
      return;
    }

    const ownerElement = clickTargetAncestor ?? clickedElement;
    const clickTarget = ownerElement.getAttribute('data-click-target');
    if(!clickTarget) {
      return;
    }

    const targetElement = ownerElement.querySelector<HTMLElement>(clickTarget);
    if(!targetElement || targetElement === event.target) {
      return;
    }

    const newEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      button: mouseEvent.button,
      buttons: mouseEvent.buttons
    });

    targetElement.dispatchEvent(newEvent);

    event.preventDefault();
    event.stopPropagation();
  };

  rootElement.addEventListener('click', handleClick);
  rootElement.addEventListener('mouseup', handleClick);
}
