export function animate(element: HTMLElement | null, animation: string, delay = 0): void {
  if(element === null) {
    return;
  }

  if(delay === 0) {
    element.classList.add(animation);
    return;
  }

  element.style.visibility = 'hidden';
  setTimeout(() => {
    element.style.visibility = 'visible';
    element.classList.add(animation);
  }, delay);
}

export function setupEnterScreenAnimations(selectors: string[]): void {
  const elementsToFadeUpOnEnterScreen = document.querySelectorAll(selectors.join(', ')) as NodeListOf<HTMLElement>;

  elementsToFadeUpOnEnterScreen.forEach((element) => {
    element.style.visibility = 'hidden';
  });

  triggerOnEnterScreen((element) => {
    element.style.visibility = 'visible';
    animate(element, 'fade-up');
  }, elementsToFadeUpOnEnterScreen);
}

export function triggerOnEnterScreen(
  enterScreenCallback: (element: HTMLElement) => void,
  elements: NodeListOf<Element> | Element[]
): void {

  const enterScreenObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting) {
        const element = entry.target;
        enterScreenCallback(element as HTMLElement);
        enterScreenObserver.unobserve(element);
      }
    });
  });

  elements.forEach((element) => {
    enterScreenObserver.observe(element);
  });
}
