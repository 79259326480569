<script lang="ts">
  import type { Option } from '../utils/types';

  export let options: Option[];
  export let name: string;
  export let title: string;
  export let checked: string[] = [];
</script>

<fieldset>
  <legend>{ title }</legend>
  { #each options as option }
    <div class="checkbox-container">
      <input
        type="checkbox"
        name="{ name }"
        id="{ name }-{ option.value }"
        value="{ option.value }"
        bind:group="{ checked }">

      <span class="checkmark">
        <i class="xlent-icon-checkmark"></i>
      </span>

      <label for="{ name }-{ option.value }">
        { option.label }
      </label>
    </div>
    { /each }
</fieldset>
