<svelte:options
  customElement={{
    tag: 'xlent-filterable-listing',
    shadow: 'none',
    props: {
      listingHeading: { attribute: 'listing-heading' },
      listingText: { attribute: 'listing-text' },
      showTags: { attribute: 'show-tags', type: 'Boolean' },
      pageSize: { attribute: 'page-size', type: 'Number' },
      initialQueryString: { attribute: 'initial-query-string' },
      loadMoreText: { attribute: 'load-more-text' },
      useFilters: { attribute: 'use-filters', type: 'Boolean' },
      filtersJson: { attribute: 'filters-json' },
      filterHeading: { attribute: 'filter-heading' },
      noResultsText: { attribute: 'no-results-text' }
    }
  }}
/>

<script lang="ts">
  import { isEmptyOrWhiteSpace } from '../utils/string';
  import Listing from './Listing.svelte';
  import CheckboxGroup from './CheckboxGroup.svelte';
  import type { Filter, Option } from '../utils/types';
  import { updateQueryParameter } from '../utils/url';

  export let listingHeading: string;
  export let listingText: string;
  export let showTags: boolean;
  export let pageSize: number;
  export let initialQueryString: string;
  export let loadMoreText: string;
  export let useFilters = false;
  export let filtersJson: string | null = null;
  export let filterHeading: string | null = null;
  export let noResultsText: string;

  const filters: Filter[] = filtersJson !== null ? JSON.parse(filtersJson) : [];
  const checkboxOptions: Option[] = filters.map((filter) => {
    return {
      label: filter.TranslationValue,
      value: filter.QueryParameter
    };
  });

  let checkedFilters: string[] = [];

  $: queryString = checkedFilters.length > 0 ?
    updateQueryParameter(initialQueryString, 'types', checkedFilters.join(','))
    : initialQueryString;
</script>

<div class="filterable-listing">
  <div class="heading-filters-container { useFilters ? 'filters-on' : ''}">
    <div class="description">
      { #if isEmptyOrWhiteSpace(listingHeading) }
        <h2>{ listingHeading }</h2>
      { /if }

      { #if isEmptyOrWhiteSpace(listingText) }
        <p>{ listingText }</p>
      { /if }
    </div>

    { #if useFilters && checkboxOptions.length > 0 && filterHeading !== null }
      <CheckboxGroup
        options="{ checkboxOptions }"
        name="filter"
        title="{ filterHeading }"
        bind:checked="{ checkedFilters }" />
    { /if }
  </div>

  <Listing
    loadMoreText="{ loadMoreText }"
    pageSize="{ pageSize }"
    queryString="{ queryString }"
    showTags="{ showTags }"
    noResultsText="{ noResultsText }"/>
</div>
